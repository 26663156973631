<!--  -->
<template>
    <div class="curriculum">
        <entrance></entrance>
        <div class="iput flex-align-center">我的字帖</div>
        <div class="main flex-layout">
            <div class="flex-layout">
                <div class="switchType">
                    <div class="flex-align-center" :class="{ btncolor: switchIndex == index }"
                        v-for="(item, index) in switchTypeList" :key="index" @click="switchclick(index)">
                        {{ item }}
                    </div>
                </div>
                <!-- 单元字帖 -->
                <div class="content" v-if="switchIndex==0">
                    <div class="option flex-align-between">
                        <div class="optionWrap">
                            <div class="grade flex-align">
                                <span>选择年级:</span>
                                <span :class="currentIndex==-1?'actived':''" @click="chooseClass(-1,'')">全部</span>
                                <span :class="currentIndex==index?'actived':''" v-for="(item,index) in classList"
                                    :key="index" @click="chooseClass(index,item.id)">{{item.name}}</span>
                            </div>
                            <div class="type flex-align">
                                <span>字帖类型:</span>
                                <span :class="{'actived':currentType==0}" @click="chooseType(0)">全部</span>
                                <span :class="{'actived':currentType==1}" @click="chooseType(1)">课堂写字单</span>
                                <span :class="{'actived':currentType==2}" @click="chooseType(2)">篇章字帖</span>
                            </div>
                        </div>
                        <div class="create flex-align-around" @click="createWord">
                            <div class="flex-align-around">
                                <img src="@/assets/copybook/add2.png" alt="">
                                新建字帖
                            </div>
                        </div>
                    </div>
                    <div class="itemWrap flex-align-between">
                        <template v-if="copyList.length>0">
                            <div class="copyItem flex-column-center" v-for="(item,index) in copyList" :key="index">
                                <div class="itemContent flex-column-center">
                                    <span>{{item.name}}</span>
                                    <img :class="[item.type==1?'word_img':'text_img']" :src="item.cover"
                                        v-if="item.cover">
                                    <img class="word_img" src="@/assets/copybook/word_default.png"
                                        v-if="!item.cover&&item.type==1">
                                    <img class="text_img" src="@/assets/copybook/text_default.png"
                                        v-if="!item.cover&&item.type==2">
                                </div>
                                <div class="edit flex-align-center" @click="edit(item.modelId)">
                                    <div class="flex-align">
                                        <img src="@/assets/copybook/edit.png" alt="">
                                        编辑
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="empty flex-column-center" v-else>
                            <img src="@/assets/empty.png" />
                            <span>没有查到相关字帖~</span>
                        </div>
                    </div>
                    <div class="page flex-align-center">
                        <el-pagination key="defeault" background layout="prev, pager, next,jumper" :page-size="4"
                            :total="total" @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>
                <!-- 我创建的 -->
                <div class="content2" v-else>
                    <div class="top flex-align">
                        <!-- <a href=""></a> -->
                        <el-input class="myInput" placeholder="输入关键词搜索" suffix-icon="el-icon-search" @change="search"
                            v-model="searchVal">
                        </el-input>
                        <div class="create flex-align-around" @click="createWord">
                            <div class="flex-align-around">
                                <img src="@/assets/copybook/add2.png" alt="">
                                新建字帖
                            </div>
                        </div>
                    </div>
                    <div class="itemWrap flex-align">
                        <template v-if="copyList.length>0">
                            <div class="myItem" v-for="(item,index) in copyList" :key="index">
                                <div class="item flex-align-around">
                                    <div class="loading" v-if="item.status==3">字帖生成中...</div>
                                    <img :src="item.cover" @click="preview(item.status,item.filePath)"
                                        v-if="item.cover">
                                    <img src="@/assets/copybook/type1_default.png" v-if="!item.cover&&item.type==1">
                                    <img src="@/assets/copybook/type2_default.png" v-if="!item.cover&&item.type==2">
                                    <img src="@/assets/copybook/type3_default.png" v-if="!item.cover&&item.type==4">
                                    <div class="menu flex-between">
                                        <div class="edit flex-align-center" :class="{'active':item.status==4}"
                                            @click="editMine(item.status,item.modelId)">
                                            编辑</div>
                                        <div class="download flex-align-center" :class="{'active':item.status==4}"
                                            @click="preview(item.status,item.filePath)">下载</div>
                                    </div>
                                </div>
                                <span class="name">{{item.name}}</span>
                                <span class="time">{{transformTimestamp(item.createDate).split(' ')[0]}}</span>
                            </div>
                        </template>
                        <div class="empty flex-column-center" v-else>
                            <img src="@/assets/empty.png" />
                            <span>没有查到相关字帖~</span>
                        </div>
                    </div>
                    <div class="page flex-align-center">
                        <el-pagination key="myCreate" background layout="prev, pager, next,jumper" :page-size="4"
                            :total="total" @current-change="myCreatChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 选择字帖模板弹窗 -->
        <el-dialog :visible='dialogVisible' :before-close="handleClose" center title="新建字帖">
            <div class="templateWrap flex-align-around">
                <div class="type_word flex-column-center">
                    <img src="@/assets/copybook/type1.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(1)">
                        <div class="flex-align-around">
                            创建写字单字帖
                        </div>
                    </div>
                </div>
                <div class="type_text flex-column-center">
                    <img src="@/assets/copybook/type2.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(2)">
                        <div class="flex-align-around">
                            创建篇章字帖
                        </div>
                    </div>
                </div>
                <div class="type_text flex-column-center">
                    <img src="@/assets/copybook/type3.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(4)">
                        <div class="flex-align-around">
                            创建生字字帖
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 创建副本在编辑弹窗 -->
        <el-dialog custom-class="myDialog" top='30vh' :visible='dialogVisible2' :before-close="handleClose2" center
            title="创建副本再编辑">
            <div class="tipWrap flex-column-center">
                <span>这是一个单元字帖模板，请创建副本并编辑</span>
                <div class="btns flex-align">
                    <div class="cancel" @click="handleClose2">取消</div>
                    <div class="make" @click="editDefault">创建副本</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import entrance from "../../Common_components/head/entrance";
    import subscribe from "../../Common_components/Popup/subscribe.vue";
    import {
        mapState,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                switchTypeList: ["单元字帖", "我创建的"],
                switchIndex: 0,
                searchVal: '',
                dialogVisible: false,
                dialogVisible2: false,
                classList: [],
                currentIndex: -1,
                currentType: 0,
                currentGradeId: '',
                page: 1,
                copyList: [],
                total: null,
                modelId: null
            };
        },
        components: {
            entrance,
            subscribe,
        },
        //方法
        methods: {
            ...mapMutations(["hint_data"]),
            switchclick(index) {
                this.switchIndex = index;
                this.searchVal = '';
                this.page = 1;
                this.copybookList(index)
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.page = val;
                this.copybookList(this.switchIndex)
            },
            myCreatChange(val) {
                console.log(`当前页: ${val}`);
                this.page = val;
                this.copybookList(this.switchIndex)
            },
            // 新建字帖
            createWord() {
                this.dialogVisible = true
            },
            goCreate(type) {
                let query = {
                    type
                }
                this.$router.push({
                    path: "/createWord",
                    query
                })
            },
            handleClose() {
                this.dialogVisible = false
            },
            handleClose2() {
                this.dialogVisible2 = false
            },
            getClassList() {
                this.$Api.CopyBook.getGrade()
                    .then(res => {
                        this.classList = res.data
                    })
                    .catch(err => {
                        console.log('获取班级失败', err)
                    })
            },
            chooseClass(index, classId) {
                this.currentIndex = index;
                this.currentGradeId = classId;
                this.copybookList(index)
            },
            chooseType(type) {
                this.currentType = type;
                this.copybookList(0)
            },
            // 获取字帖分页
            copybookList(index) {
                if (index == 0) {
                    let data = {
                        gradeId: this.currentGradeId,
                        type: this.currentType,
                        page: this.page,
                        limit: 4,
                    }
                    this.$Api.CopyBook.getCopyBookList(data)
                        .then(res => {
                            console.log(res);
                            res.data.list.map(item => {
                                try {
                                    item.name = item.name.split("-")[1]
                                } catch (err) {
                                    console.log(err)
                                }
                            })
                            this.copyList = res.data.list;
                            this.total = res.data.totalCount;
                        })
                        .catch(err => {
                            console.log('获取字帖失败', err)
                        })
                } else {
                    let data = {
                        // gradeId: this.currentGradeId,
                        name: this.searchVal,
                        type: this.currentType,
                        page: this.page,
                        limit: 4,
                        isUser: 1
                    }
                    this.$Api.CopyBook.getCopyBookList(data)
                        .then(res => {
                            console.log(res);
                            this.copyList = res.data.list;
                            this.total = res.data.totalCount;
                        })
                        .catch(err => {
                            console.log('获取字帖失败', err)
                        })
                }

            },
            //时间转换(js将 “2021-07-06T06:23:57.000+00:00” 转换为年月日时分秒)
            transformTimestamp(timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear() + '-';
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
                // const s = date.getSeconds(); // 秒
                const dateString = Y + M + D + h + m;
                // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
                return dateString;
            },
            // 预览字帖、下载字帖
            preview(status, url) {
                console.log(status);
                if (status == 4) {
                    window.open(url);
                }
            },
            // 编辑字帖(字帖模板)
            edit(modelId) {
                this.modelId = modelId;
                this.dialogVisible2 = true
            },
            // 编辑字帖(我创建的)
            editMine(status, modelId) {
                if (status == 4) {
                    let query = {
                        modelId,
                        isEdit: true
                    }
                    this.$router.push({
                        path: "/createWord",
                        query
                    })
                }
            },
            // 编辑字帖(字帖模板)
            editDefault() {
                let query = {
                    modelId: this.modelId,
                    isEdit: true,
                    copyType: 'defaultMode'
                }
                this.$router.push({
                    path: "/createWord",
                    query
                })
            },
            // 搜索字帖
            search(val) {
                console.log(val);
                this.searchVal = val;
                this.copybookList(1)
            }

        },
        mounted() {
            this.getClassList();
            if (this.$route.query.fromPath) {
                this.switchIndex = 1;
                this.copybookList(1);
            } else {
                this.copybookList(0);
            }
        }
    };
</script>
<style lang='less' scoped>
    @import "./less/copybook2.less";
</style>